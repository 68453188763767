// @flow strict

import './Image.css';

type Props = {
  category: string;
  name: string;
  alt?: string;
  best?: boolean;
  width?: number;
  height: number;
  fit?: boolean;
};

const Image = ({ category, name, alt, best, width, height = 1024, fit = false }: Props) => {
  const optionalWidth = width ? `,w_${width},${fit ? 'c_pad' : 'c_fill,g_auto'}` : '';
  const quality = best ? ':best' : '';
  const baseURL = `https://res.cloudinary.com/qiuqi/h_${height}${optionalWidth},c_lfill,f_auto,q_auto${quality},fl_progressive:steep`;

  return (
    <article className="Image">
      <div className="content">
        <img className="image" src={`${baseURL}/${category}/${name}.jpg`} alt={alt ?? name.replace(/-/g, ' ')} />
      </div>
      <div className="content">
        <img className="image" loading="lazy" src={`https://res.cloudinary.com/qiuqi/w_88/${category}/${name}.jpg`} />
      </div>
    </article>
  );
};

export default Image;
